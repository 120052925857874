<template>
    <b-modal title="Form Persetujuan Retur" id="supplier-modal" size="md" no-close-on-backdrop no-close-on-esc>
        <b-form-group label="Pilih Supplier">
            <v-select :options="supplierOptions" label="text" :reduce="option => option.value" v-model="supplier_id"></v-select>
        </b-form-group>
        <template #modal-footer>
            <b-button variant="primary" @click.prevent="submit">Setujui</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    components: {
        BModal, BFormGroup, BButton, vSelect
    },
    data: () => ({
        supplierOptions: [],
        supplier_id: null
    }),
    methods: {
        submit() {
            if(!this.supplier_id) {
                this.displayError({
                    message: 'Harap pilih Supplier!'
                })

                return false
            }

            this.$swal({
                title: 'Anda yakin?',
                text: `Data retur ini akan anda setujui`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(res => {
                if(res.value) {
                    const payload = {
                        id: this.$route.params.id,
                        supplier_id: this.supplier_id,
                        approve: 1 // approve
                    }

                    this.$emit('submit', [payload])
                }
            })
        }
    },
    async created() {
        const params = {}
        if(this.isGM) params.jenis = 2
        const suppliers = await this.$store.dispatch('supplier/getData', params)
        this.supplierOptions = suppliers.map(s => ({value: s.id, text: s.instansi}))
    }
}
</script>